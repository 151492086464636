// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-mendix-world-js": () => import("./../../../src/pages/mendix-world.js" /* webpackChunkName: "component---src-pages-mendix-world-js" */),
  "component---src-pages-nieuws-archief-js": () => import("./../../../src/pages/nieuws-archief.js" /* webpackChunkName: "component---src-pages-nieuws-archief-js" */),
  "component---src-pages-plava-js": () => import("./../../../src/pages/plava.js" /* webpackChunkName: "component---src-pages-plava-js" */),
  "component---src-templates-news-post-js": () => import("./../../../src/templates/news-post.js" /* webpackChunkName: "component---src-templates-news-post-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */)
}

